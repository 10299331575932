<template>
    <div class="scroll-container" id="scrollBox" @scroll="showScroll()">
        <div class="main-content">
            <empty-box v-if="dataList&&dataList.length==0"></empty-box>
            <div class="room-group-item"
                 v-for="(item,index) in dataList"
                 v-else
                 :key="index">
                <h3 class="type-name">
                    {{index}}
                    <span class="sum-num">({{item.length}})</span>
                </h3>
                <ul class="room-list">
                    <li v-for="room in item"
                        :class="itemClass(room.room_state)"
                        class="room-item"
                        @click="showRoomDetail(room)">
                        <div class="card-top">
                            <p class="room-name">{{room.room_name}}</p>
                            <span class="bed-state">{{room.bed_occupy?room.bed_occupy:0}}/{{room.bed_count}}</span>
                            <span class="pay-state" :class="room.is_payoff==0?'red-bg':'white-bg'"
                                  v-if="room.room_state==2">
                            {{room.is_payoff==0?'未结':'已结'}}
                        </span>
                        </div>
                        <div class="room-detail" :class="state=='total'||state==2?'':'state-detail'">
                            <ul class="service-list" v-if="room.room_state==2">
                                <li class="service-item" v-for="(service,index) in room.service_list" v-if="service.staff_no&&service.work_type">
                                    <span class="staff-no">{{service.staff_no}}</span>
                                    <span class="work-type">{{service.work_type|workTypeData}}</span>
                                    <span class="remain-time"
                                          :class="service.remain_time<0?'overtime':''">{{service.remain_time?service
                                                                                   .remain_time:"0"}}</span>
                                </li>
                            </ul>
                            <!--房间状态-->
                            <p class="room-state" v-else>{{room.room_state|roomState}}</p>
                            <div class="icon-box">
                                <i class="clean-icon" v-if="room.room_state==3||room.need_clean==1"></i>
                                <i class="message-icon" v-if="room.is_message==1"></i>
                                <i class="mend-icon" v-if="room.room_state==4"></i>
                                <i class="lack-icon" v-if="room.room_state==2&&room.is_lack==1">缺</i>
                            </div>
                        </div>
                    </li>
                    <li class="room-item" style="height: 0;margin: 0;" v-for="i in 10" ></li>
                </ul>
            </div>
        </div>
        <daqi-copyright></daqi-copyright>
    </div>
</template>

<script>
	import {mapState} from 'vuex'
	// import RoomDetail from "./room-detail";
	// import DaqiCopyright from "../../components/daqi-copyright";

	export default {
		name:"room-list",
		props:["dataList","state"],
		data(){
			return {
				staffNo:null,
				scrollTop:0,
			}
		},
		computed:{
			...mapState([
				'openid',
				'shopNo',
			]),
			itemClass(){
				return function(state){
					switch(state){
						case 1:
							return 'idle-room'
							break
						case 2:
							return 'guess-room'
							break
						case 3:
							return 'dirty-room'
							break
						case 4:
							return 'break-room'
							break
					}
				}
			},
		},
		components:{
			// DaqiCopyright,
			'empty-box':() => import('@/components/empty-box.vue'),
			// RoomDetail,
		},
		filters:{
			roomState(state){
				switch(state){
					case 1:
						return '空闲'
						break
					case 3:
						return '脏房'
						break
					case 4:
						return '故障房'
						break
                    default:
                    	return ''
                        break
				}
			},
			workTypeData(type){
				switch(type){
					case "轮钟":
						return '轮'
						break
					case "点钟":
						return '点'
						break
					case "Call钟":
						return 'Call'
						break
					case "加钟":
						return '加'
						break
					case "跳牌":
						return '跳'
						break
					case "买钟":
						return '买'
						break
					default:
						return ''
						break
				}
			},
		},
		methods:{
			/**
             * 显示房间详情
			 */
			showRoomDetail(room){
				if(room.room_state==2&&room.service_list.length){
					this.$emit("showRoomDetail",room)
                }
            },
			showScroll(){
				this.scrollTop = document.getElementById('scrollBox').scrollTop
            },
		},
		watch:{
			scrollTop:{
				handler(val){
					if (val === 0) {
						this.$store.commit("updateAllowPull",true)
					}else{
						this.$store.commit("updateAllowPull",false)
					}
				}
			},
		},
	}
</script>

<style lang="scss" type="text/scss" scoped>
    @import "~@/assets/style/public.scss";

    .scroll-container{
        overflow-y:auto;
        flex:1;
        padding:18px 0 0;
    }
    .main-content{
        min-height:calc(100% - 101px);
    }
    .room-group-item{
        background:#FFFFFF;
        padding:0 30px;
    }

    .type-name{
        font-size:28px;
        font-weight:bold;
        line-height:80px;
        border-bottom:1px solid $border-color;
    }

    .sum-num{
        color:$high-light-color;
    }

    .room-list{
        display:flex;
        flex-wrap:wrap;
        justify-content:space-between;
        padding:26px 0 5px 0;
        &:after {
            content: "";
            width:220px;
        }
    }
    .room-item{
        width:220px;
        margin:0 0 14px;
        border-radius:6px
    }
    .card-top{
        position:relative;
        color:#FFFFFF;
        height:75px;
        padding:0 0 0 10px;
        background:#93A3B5;
        border-radius:4px 4px 0 0;
    }

    .idle-room .card-top{
        background:#93a3b5;
    }

    .guess-room .card-top{
        background:#15bb44;
    }

    .dirty-room .card-top{
        background:#5759be;
    }

    .break-room .card-top{
        background:#e13744;
    }
    .room-name{
        font-size:30px;
        line-height:75px;
    }
    .bed-state{
        font-size:24px;
        line-height:1.5;
        position:absolute;
        right:0;
        top: 0;
        display:block;
        padding:2px 10px;
    }
    .pay-state{
        position:absolute;
        bottom:4px;
        right:5px;
        display:block;
        width:60px;
        height:30px;
        font-size:22px;
        border-radius:4px;
        line-height:30px;
        text-align:center;
    }
    .white-bg{
        color:#000000;
        background:#fff;
    }
    .red-bg{
        color:#fff;
        background:#e13744;
    }
    .room-detail{
        padding:5px 10px;
        height:150px;
        border-left:2px solid $border-color;
        border-right:2px solid $border-color;
        border-bottom:2px solid $border-color;
        border-radius:0 0 6px 6px;
        display:flex;
        font-size:22px;
        overflow:hidden;
    }
    .state-detail{
        height:80px;
    }
    .service-list,.room-state{
        flex:1;
        overflow:hidden;
    }
    .service-item{
        display:flex;
        padding-right:30px;

    }
    .staff-no,.work-type{
        flex:1;
        display:inline-block;
        text-align:center;
        white-space:nowrap;
        // overflow:hidden;
        // text-overflow:ellipsis
    }
    // .staff-no{
    //   flex: 8 !important;
    // }
    .remain-time{
        width:70px;
        text-align:center;
        white-space:nowrap;
        overflow:hidden;
    }
    .overtime{
        color:#ec2108;
    }
    .lack-icon{
        display:block;
        width:32px;
        height:32px;
        font-size:26px;
        color:#e13744;
        border: 1px solid #e13744;
        border-radius:4px;
        text-align:center;
        line-height:32px;
    }
    .clean-icon,.message-icon,.mend-icon{
        display:block;
        width:30px;
        height:30px;
        margin-bottom:10px;
    }
    .clean-icon{
        background:url("~@/assets/images/clean.png") no-repeat;
        background-size: 100% 100%;
    }
    .message-icon{
         background:url("~@/assets/images/message.png") no-repeat;
         background-size: 100% 100%;
    }
    .mend-icon{
        background:url("~@/assets/images/mend.png") no-repeat;
        background-size: 100% 100%;
    }

</style>
